import React, { useEffect, useState } from 'react';
import reactCookie from 'react-cookie';
import Link from '../../common/Link';

const DesktopNav = ({ showNavLine }: { showNavLine: boolean }) => {
  const [admin, setAdmin] = useState(false);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setAdmin(reactCookie.load('aquinna'));
  }, []);

  const aquinnaClick = () => {
    // Hack to hide hover dropdown when navigating away
    setHide(true);
    setTimeout(() => setHide(false), 500);
  };

  return (
    <nav className="navigation main hidden-xs hidden-ms">
      <div className="container" style={{ position: 'relative' }}>
        <div className="col-sm-3 logo">
          <Link to="/" className="navbar-brand">
            <img
              src="/images/logo.png"
              alt="Aquinna Homes"
              className="img-responsive"
              width={193}
              height={67}
            />
          </Link>
          {showNavLine && (
            <div className="aspire">
              <img src="/images/aspire.png" alt="" style={{ height: 18 }} />
            </div>
          )}
        </div>
        <div className="col-sm-9">
          <div className="row nav-row">
            <div className="col-lg-12">
              <ul className="nav nav-pills nav-justified" role="menu">
                <li className="nav-homes" role="presentation">
                  <Link data-target="main-dropdown" to="/developments">
                    <span className="visible-sm">Our Homes</span>
                    <span className="hidden-sm">Current Developments</span>
                  </Link>
                </li>
                <li>
                  <Link to="/forthcoming">Coming Soon</Link>
                </li>
                <li role="presentation">
                  {/* eslint-disable-next-line */}
                  <a style={{ cursor: 'pointer' }}>
                    <span className="hidden-sm">Distinctly</span> Aquinna
                  </a>
                  <ul
                    className={`submenu ${hide ? 'hide' : ''}`}
                    role="menu"
                    onClick={aquinnaClick}
                  >
                    <li role="presentation">
                      <Link to="/about" role="menuitem">
                        About Us
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/portfolio" role="menuitem">
                        Portfolio
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/land" role="menuitem">
                        Land Opportunities
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/joint-ventures" role="menuitem">
                        Joint Ventures
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/design" role="menuitem">
                        Bespoke Design
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/contracting" role="menuitem">
                        Build Contracting
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/news" role="menuitem">
                        Press Releases
                      </Link>
                    </li>
                    <li role="presentation">
                      <Link to="/contact" role="menuitem">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {admin && (
          <div
            className="visible-lg visible-md visible-sm"
            style={{ position: 'absolute', right: 0 }}
          >
            <a href="/admin">Admin Panel</a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default DesktopNav;
